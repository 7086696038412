'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.service:ReportFilterService

 # @description

###
class ReportFilterService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $q
    $window
    $timeout
    ReportConfigurationManager
    ReportConfigurationService
    UserPermissions
    UiHelpers
    $translate
    _
    uuid4
    Restangular
    MundoMap
    MundoSettings
  ) ->

    @getChoicesByLabel = (cfg, filter, label) ->
      return $q (resolve, reject) ->
        if filter.options? and
        filter.options.choices? and
        filter.options.choices.length > 0
          choices = $filter('orderBy')(filter.options.choices, 'value')

          if label? and label.length
            choices = _.filter choices, (v) ->
              _.includes v.value.toLowerCase(), label.toLowerCase()

          if filter.active? and
          filter.active.selectedItems? and
          filter.active.selectedItems.length
            choices = choices.filter (v) ->
              for x in filter.active.selectedItems
                if x.id == v.id
                  return false
              return true

          resolve choices
        else
          ReportConfigurationManager
            .one cfg['_id']
            .one 'filters'
            .one filter.code
            .getList 'choices', {
              limit: 99
              label: label
            }
            .then (results) ->
              # Return filtered standard choices
              choices = $filter('orderBy')(results, 'value')

              if label? and label.length
                choices = _.filter results, (choice) ->
                  _.includes choice.value.toLowerCase(), label.toLowerCase()

              if filter.active? and
              filter.active.selectedItems? and
              filter.active.selectedItems.length
                choices = choices.filter (v) ->
                  for x in filter.active.selectedItems
                    if x.id == v.id
                      return false
                  return true

              resolve choices
            , () ->
              reject()

    @getChoiceById = (cfg, filter, id) ->
      return $q (resolve, reject) ->
        ReportConfigurationManager
          .one cfg['_id']
          .one 'filters'
          .one filter.code
          .getList 'choices', {
            limit: 99,
            id: id
          }
          .then (results) ->
            results = results.filter (v) -> v.id == id
            resolve results[0]
          , () ->
            reject()

    @prepareFiltersParameterForBackend  = (reportFilters) ->
      # Create the filter-array in the correct format
      # By looping the sourceFilters and creating the correct object format
      # Correct format = '{code: 'code', operand: 'operand', value: 'value'}'
      filterArray = []
      angular.forEach reportFilters, (sourceFilter) ->
        sourceFilter.operand ?= if 'EQ' in sourceFilter.operands then 'EQ' else sourceFilter.operands[0]

        if !sourceFilter.locked
          switch sourceFilter.type
            when 'choice'
              if sourceFilter.options.multiple
                if sourceFilter.selectedItems && sourceFilter.selectedItems.length > 0
                  ids = []
                  for selectedItem in sourceFilter.selectedItems
                    ids.push selectedItem.id

                  filterArray.push {code: sourceFilter.code, operand: sourceFilter.operand, value: ids}
              else
                if sourceFilter.filterValue
                  filterArray.push {
                    code: sourceFilter.code,
                    operand: sourceFilter.operand,
                    value: sourceFilter.filterValue.id
                  }
            when 'collection'
              if sourceFilter.filterValue && sourceFilter.filterValue != null
                switch sourceFilter.options.entry_type
                  when 'datetime'

                    filterFromDate = moment(sourceFilter.filterValue[0]).format()
                    filterTillDate = moment(sourceFilter.filterValue[1]).format()
                    filterArray.push {
                      code: sourceFilter.code
                      operand: sourceFilter.operand
                      value: [filterFromDate, filterTillDate]
                    }
                  else
                    $log.debug 'collection entry filter type not found'
            when 'address_point_radius'
              if sourceFilter.filterValue && sourceFilter.filterValue != null &&
              sourceFilter.filterValue[0] && sourceFilter.filterValue[1]
                filterArray.push {
                  code: sourceFilter.code
                  operand: sourceFilter.operand
                  value: [sourceFilter.filterValue[0], sourceFilter.filterValue[1]]
                }
            when 'text'
              if sourceFilter.filterValue && sourceFilter.filterValue != null
                filterArray.push
                  code: sourceFilter.code
                  operand: sourceFilter.operand
                  value: sourceFilter.filterValue
            when 'integer'
              if sourceFilter.filterValue && sourceFilter.filterValue != null
                filterArray.push
                  code: sourceFilter.code
                  operand: sourceFilter.operand
                  value: sourceFilter.filterValue
            else
              $log.debug 'filterType not found', sourceFilter.type
      return filterArray

    @filterIsAlreadyUsed = (reportFilters, element)->
      if (reportFilters) && (angular.isArray reportFilters) && (reportFilters.length > 0)
        found = _.find reportFilters, (f) ->
          return f['code'] == element['code']
        if found
          false
        else
          true
      else
        true

    @getReportFilters = (source, filters, whitelist = null) ->
      filters = filters
        .filter (v) ->
          if not whitelist? or whitelist.length == 0
            return true
          else
            return _.indexOf(whitelist, v.code) != -1
        .filter (v) ->
          if angular.isArray v.active.value
            return v.active.value? and v.active.value.length
          return v.active.value?
        .map (v) ->
          return {
            code: v.code,
            operand: v.active.operand,
            value: v.active.value,
          }

      filters

    @getFilterCategories = (source) ->
      categories = _.filter source.filters, (item) ->
        return typeof item.category != "undefined"

      categories = _.map categories, (item) ->
        return item.category

      categories = angular.copy categories
      categories = _.uniq categories

      return categories

    @selectFilters = (src, cfg, whitelist = null) =>

      data = @getFilterData src, cfg, whitelist

      return $q (resolve, reject) =>
        UiHelpers.openDialog
          template: 'mundo-reporting/views/dialogs/report-filter-selection-dialog.tpl.html'
          title: 'app.report.filters'
          dialogClass: 'filter-dialog'
          data:
            filtering: data
          onComplete: () ->
            if data? and data.filters?
              for x in data.filters
                if x? and x.active? and x.active.map?
                  x.active.map.updateSize()

          onConfirm: (result) =>
            filters = @getReportFilters src, data.filters

            ReportConfigurationService
              .patchConfiguration cfg,
                filters: filters
              .then () ->
                resolve()
              , () ->
                reject()
          onCancel: () ->
            reject()

    @setDefaultFilters = (source, filters) ->
      return $q (resolve, reject) ->
        if not (filters? and filters.length)
          MundoSettings
            .remove "reports.#{source.code}.filters.default"
            .then () ->
              resolve()
            , () ->
              reject()
        else
          MundoSettings
            .set "reports.#{source.code}.filters.default", filters
            .then () ->
              resolve()
            , () ->
              reject()

    @getFilterData = (src, cfg, whitelist = null) =>

      filters = angular.copy(cfg.filters)
        .filter (v) -> src.filters[v.code]?
        .map (v) ->
          res = angular.copy src.filters[v.code]
          res.active = v
          return res
        .sort (a, b) ->
          return if a.active? and a.active.locked then -1 else 1

      availableFilters = angular.copy _.values(src.filters)
      availableFilters = availableFilters
        .filter (v) -> whitelist == null || (whitelist.length > 0 and _.indexOf(whitelist, v.code) != -1)
      filterCategories = @getFilterCategories(src)

      if(availableFilters.length < 8)
        filterCategories = []

      data =
        changed: false
        filters: filters
        filterCategories: filterCategories
        originalFilters: angular.copy filters
        filterQuery: null
        filterCategory: null
        availableFilters: availableFilters
        state:
          progress: false

      # We start out adding filters if we have none yet
      data.adding = not data.filters.length

      ### Generic filter CRUD methods ###

      data.searchFilters = (v) ->
        if (data.filterCategory?) and (data.filterCategory != v.category)
          return false
        if not (data.filterQuery? and data.filterQuery.length)
          return true

        searchValue = data.filterQuery.toLowerCase()
        compareValue = $translate.instant("messages.reporting.filter-name.#{v.label}").toLowerCase()

        return compareValue.indexOf(searchValue) > -1

      data.toggleAdding = (state) ->
        data.adding = if state? then state else (not data.adding)

        if not data.adding
          data.filterQuery = null

      data.add = (filter) ->
        filter = angular.copy filter
        filter.active =
          code: filter.code
          operand: if 'EQ' in filter.operands then 'EQ' else filter.operands[0]
          value: if filter.options? and filter.options.multiple then [] else null

        data.filters.push filter
        data.toggleAdding false

      data.setCategory = (category) ->
        data.filterCategory = category

      data.undo = () ->
        data.filters = angular.copy data.originalFilters
        data.changed = false

      data.remove = (key) ->
        data.filters.splice(key, 1)

      data.clear = () ->
        locked = data.filters.filter (v) -> v.active.locked
        data.filters.splice(0, data.filters.length)
        (data.filters.push(x) for x in locked)

      data.canSetAsDefault = () ->
        return UserPermissions.check 'manage all MundoMosaSettingsBundle:Setting entities'

      data.setAsDefault = () =>
        data.state.progress = true
        filters = @getReportFilters src, data.filters
        @setDefaultFilters src, filters
        .then () ->
          data.state.progress = false
        , () ->
          data.state.progress = false

      ### Choice filter methods ###

      data.transformChip = (chip) ->
        return chip

      data.getFilteredChoices = (filter) =>
        return @getChoicesByLabel cfg, filter, filter.active.searchQuery

      data.updateChoice = (filter) =>
        init = not filter.active.initialized
        filter.active.initialized = true

        values = filter.active.value
        values ?= []
        values = if angular.isArray values then values else [values]

        if not filter.active.selectedItem?
          filter.active.selectedItem = null

          if init and (not filter.options.multiple)
            for x in values
              @getChoiceById cfg, filter, x
                .then (result) ->
                  filter.active.selectedItem = result

        if not filter.active.selectedItems?
          filter.active.selectedItems = []

          if init and filter.options.multiple
            for x in values
              @getChoiceById cfg, filter, x
                .then (result) ->
                  filter.active.selectedItems.push result

        if not init
          if filter.options.multiple
            filter.active.value = []
            for x in filter.active.selectedItems
              filter.active.value.push x.id
          else
            filter.active.value = if filter.active.selectedItem? then filter.active.selectedItem.id else null

      ### Datetime filter methods ###

      data.updateDateTime = (filter) ->
        init = not filter.active.initialized
        filter.active.initialized = true

        if not filter.active.value?
          filter.active.value = [null, null]

        startValue = filter.active.value[0]
        startValue ?= (new Date()).toISOString()
        if not /[Z|+]/.test(startValue)
          startValue = "#{startValue}Z"
        startTz = startValue.match(/[Z|+].*/)[0]

        startDate = filter.active.startDate
        startDate ?= new Date(startValue)

        startTime = new Date(startDate.valueOf())
        if filter.active.startTime
          startTime = new Date("2000-01-01T#{filter.active.startTime}:00#{startTz}")
          startTime.setTime(startTime.getTime() + (startTime.getTimezoneOffset() * 60 * 1000))

        if not isNaN(startTime.getTime())
          startTime.setSeconds(0)
          startTime.setMilliseconds(0)

          startDate.setHours startTime.getHours()
          startDate.setMinutes startTime.getMinutes()
          startDate.setSeconds 0
          startDate.setMilliseconds 0

          if not filter.active.startTime?
            filter.active.startTime = moment(startTime).format('HH:mm')
          # filter.active.startTime = "#{startTime.getHours()}:#{startTime.getMinutes()}"

        endValue = filter.active.value[1]
        endValue ?= (new Date()).toISOString()
        if not /[Z|+]/.test(endValue)
          endValue = "#{endValue}Z"
        endTz = endValue.match(/[Z|+].*/)[0]

        endDate = filter.active.endDate
        endDate ?= new Date(endValue)

        endTime = new Date(endDate.valueOf())
        if filter.active.endTime
          endTime = new Date("2000-01-01T#{filter.active.endTime}:00#{endTz}")
          endTime.setTime(endTime.getTime() + (endTime.getTimezoneOffset() * 60 * 1000))

        if not isNaN(endTime.getTime())
          endTime.setSeconds(0)
          endTime.setMilliseconds(0)

          endDate.setHours endTime.getHours()
          endDate.setMinutes endTime.getMinutes()
          endDate.setSeconds 59
          endDate.setMilliseconds 999

          if not filter.active.endTime?
            filter.active.endTime = moment(endTime).format('HH:mm')
          # filter.active.endTime = "#{endTime.getHours()}:#{endTime.getMinutes()}"

        filter.active.value[0] = startDate.toISOString()
        filter.active.startDate = startDate

        filter.active.value[1] = endDate.toISOString()
        filter.active.endDate = endDate

        filter.active.timeError = isNaN(startTime.getTime()) or isNaN(endTime.getTime()) or
          (filter.active.startTime.length < 5) or (filter.active.endTime.length < 5)
        filter.active.dateError = endDate.valueOf() < startDate.valueOf()

      ### Checkbox filter methods ###
      data.updateCheckbox = (filter) ->
        filter.active.value = filter.active.operand.toLowerCase() == 'true'

      ### Location radius methods ###
      data.updateLocationRadius = (filter) ->
        init = not filter.active.initialized
        filter.active.initialized = true

        if not filter.active.mapId?
          filter.active.mapId = "location-radius-filter-map-#{uuid4.generate()}"

        if not filter.active.value?
          filter.active.value = [null, null]

        if not filter.active.value[1]?
          filter.active.value[1] = if filter.options.radius_default? then filter.options.radius_default else
            ((filter.options.radius_minimum + filter.options.radius_maximum) / 2)

        if not angular.isNumber filter.active.value[1]
          filter.active.value[1] = Number(filter.active.value[1])

        if not filter.active.value[0]?
          filter.active.value[0] = '4.5203866,51.3312898'

          if $window.navigator.geolocation?
            $window.navigator.geolocation.getCurrentPosition (position) ->
              filter.active.value[0] = "#{position.coords.longitude},#{position.coords.latitude}"
              data.updateLocationRadius(filter)
            , () ->
              # Do stuff

        coords = filter.active.value[0].split(',')
        coords[0] = parseFloat(coords[0])
        coords[1] = parseFloat(coords[1])

        point = MundoMap.getGeometryFromGeoJSON
          type: 'Point'
          coordinates: [coords[0], coords[1]]

        $timeout () ->
          if not filter.active.map?
            filter.active.map = MundoMap.createInstance filter.active.mapId,
              layers: [
                  _layerType: 'Vector'
                  _layerId: 'filters'
                  _clusterable: false
                  _searchable: false
                  _zoomable: false
                  _updateWhileMoving: true
                  title: 'Filters (Base)'
                  visible: true
                  source: [
                    'Vector'
                      features: []
                  ]
                  style: null
                ,
              ]
              follow:
                enabled: true
              search:
                enabled: false

          if not filter.active.mapLayer?
            filter.active.mapLayer = MundoMap.getLayerById filter.active.map, 'filters'
            filter.active.mapLayer.setStyle (feature, resolution) ->
              view = filter.active.map.getView()
              projection = view.getProjection()
              metersPerUnit = projection.getMetersPerUnit()
              coordinates = feature.getGeometry().getCoordinates()
              pointResolution = ol.proj.getPointResolution(projection, resolution, coordinates) * metersPerUnit
              radius = filter.active.value[1] / pointResolution

              styles =
                'Point': [
                  new ol.style.Style
                    image: new ol.style.Circle
                      radius: 2
                      stroke: new ol.style.Stroke
                        color: 'rgba(0, 0, 0, 0.8)'
                        width: 2
                    zIndex: 65
                  new ol.style.Style
                    image: new ol.style.Circle
                      radius: radius
                      stroke: new ol.style.Stroke
                        color: 'rgba(0, 0, 0, 0.8)'
                        width: 2
                      fill: new ol.style.Fill
                        color: 'rgba(205, 255, 180, 0.5)'
                    zIndex: 60
                ]

              return styles[feature.getGeometry().getType()]

            filter.active.mapLayerSource = MundoMap.getLayerSource filter.active.mapLayer

          if not filter.active.mapPointFeature?
            filter.active.mapPointFeature = new ol.Feature(point)
            filter.active.mapDragFeatures = new ol.Collection [filter.active.mapPointFeature]

            filter.active.mapLayerSource.clear()
            filter.active.mapLayerSource.addFeature filter.active.mapPointFeature
            MundoMap.zoomAroundPoint filter.active.map, point, filter.active.value[1]

            filter.active.mapPointFeature.on 'change', (evt) ->
              coords = this.getGeometry().getCoordinates()
              coords = ol.proj.transform coords, 'EPSG:3857', 'EPSG:4326'
              filter.active.value[0] = "#{coords[0]},#{coords[1]}"

            dragInteraction = new ol.interaction.Modify
              features: filter.active.mapDragFeatures
              style: null
            filter.active.map.addInteraction dragInteraction
          else
            filter.active.mapPointFeature.setGeometry point
            filter.active.mapLayerSource.refresh()
            MundoMap.zoomAroundPoint filter.active.map, point, filter.active.value[1]

      data.executeLocationSearch = (filter) ->
        filter.active.searchNotFound = false
        Restangular.service 'services/geocode'
          .post
            address: filter.active.searchQuery
          .then (result) ->
            filter.active.value[0] = result.coordinates[0] + ',' + result.coordinates[1]
            data.updateLocationRadius(filter)
        , (error) ->
          filter.active.searchNotFound = true

      data


angular
  .module('mundoReporting')
  .service 'ReportFilterService', ReportFilterService
